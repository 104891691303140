import React from 'react'
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import GridContainer from "components/material-kit-pro-react/components/Grid/GridContainer.js";
import GridItem from "components/material-kit-pro-react/components/Grid/GridItem.js";
import sectionStyle from "LandingPageMaterial/Views/Sections/sectionStyle"

const NewH2 = styled("h2")(({ theme}) => ({
  ...sectionStyle.title,
  ...sectionStyle.textCenter,
  ...sectionStyle.titleBudget
}))

export default function BudgetTitle({title}) {

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}   >
          <NewH2>{title}</NewH2>
        </GridItem>
      </GridContainer>
    )
}
